* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Montserrat", sans-serif;
}

body {
  font-weight: 400;
  color: #444;
  line-height: 1;
  position: relative;
}

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  .container {
    max-width: 120rem;
  }

  .grid--2-cols {
    grid-template-columns: repeat(2, 1fr);
  }
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  html {
    font-size: 50%;
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/
@media (max-width: 44em) {
  html {
    font-size: 45%;
  }

  .grid--2-cols {
    grid-template-columns: repeat(1, 1fr);
  }

  .img-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img-box:nth-child(3) img {
    width: 50%;
  }

  .img-box:nth-child(6) img {
    width: 60%;
  }

  .img-box:nth-child(10) img {
    width: 60%;
  }

  .img-box:nth-child(2) {
    grid-row: 1;
  }

  .img-box:nth-child(6) {
    grid-row: 5;
  }
  .img-box:nth-child(10) {
    grid-row: 9;
  }
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .section-footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }

  .contact {
    grid-column: 2;
    grid-row: 1;
    justify-self: center;
  }
  .address {
    grid-column: 1;
    grid-row: 1;
  }

  .footer-logo {
    grid-row: 2;
    grid-column: 1/3;
    justify-self: center;
  }

  .whatsapp img {
    cursor: pointer;
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 24em) {
  html {
    font-size: 40%;
  }

  .section-footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }

  .contact {
    grid-column: 2;
    grid-row: 1;
    justify-self: center;
  }
  .address {
    grid-column: 1;
    grid-row: 1;
  }

  .footer-logo {
    grid-row: 2;
    grid-column: 1/3;
    justify-self: center;
  }

  .whatsapp img {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
}
