/* General  css */

header,
.section-main,
.section-footer {
  margin-bottom: 2rem;
  padding: 1.6rem 4.8rem;
}

.container {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.grid {
  display: grid;
  column-gap: 6.4rem;
  row-gap: 8.4rem;
  align-items: center;
}
.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

/* Header css */

header {
  background-color: #fff4e695;
}

.sticky-nav {
  transition: all 0.24s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: sticky;
  top: 0;
  backdrop-filter: blur(10px);
  z-index: 9999;
}

.header-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.header-container h1 {
  font-size: 3.5rem;
  color: #f76707;
}

.logo {
  width: 9rem;
}

header p {
  font-size: 1.6rem;
}

header p:first-of-type {
  margin-bottom: 0.5rem !important;
}

/* Main section css */

main {
  margin-bottom: 4.8rem;
}

.comline h2 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.comline p {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  line-height: 2.7rem;
}

.comline img {
  width: 100%;
  transition: all 0.2s;
}

.comline img:hover {
  transform: scale(1.1);
}

.comline .miller {
  width: 75%;
}

.meyle-container {
  display: flex;
  justify-content: flex-end;
}

.comline .meyle {
  width: 75%;
}

/* footers */

.contact {
  background-color: #ffe8cc;
  padding: 2rem;
}

.section-footer {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  gap: 10rem;
}

.section-footer img {
  width: 20rem;
}

.address h3,
.contact h3 {
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
}

.address ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  font-size: 1.6rem;
}

.contact span {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 1.8rem;
}

.contact button {
  border: none;
  background-color: #f76707;
  padding: 1.2rem 2.4rem;
  border-radius: 0.4rem;
  color: #fff;
  font-size: 1.6rem;
  cursor: pointer;
}

.contact button:hover {
  background-color: #e8590c;
}

.whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.whatsapp img {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.copy_rights {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 10px;
  font-weight: 500;
}

.copy_rights span {
  font-size: 1.5rem;
}
